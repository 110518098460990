import { createApp } from 'vue_shims'
import { createWebHistory, createRouter } from 'vue-router'

import AgentTeam from 'models/agent_team'
import AnswerTemplate from 'models/answer_template'
import Bot from 'models/bot'
import BotbuilderApi from 'models/botbuilder_api'
import ContextParameterHelper from 'mixins/context_parameter_helper'
import { trackRouting } from 'helpers'

import AnswerTemplateCreator from 'components/answer_templates/answer_template_creator.vue'
import AnswerTemplateEditor from 'components/answer_templates/answer_template_editor.vue'
import AnswerTemplateList from 'components/answer_templates/answer_template_list.vue'

initAnswerTemplatesApp = (element) ->
  routes = [
    name: 'answerTemplateList',
    path: '/',
    component: AnswerTemplateList,
  ,
    name: 'answerTemplate',
    path: '/:templateId',
    component: AnswerTemplateEditor,
  ,
    name: 'newAnswerTemplate',
    path: '/',
    component: AnswerTemplateCreator,
  ].map (item) -> Object.assign(item, path: '/projects/:projectId/:stageLevel/answer_templates2' + item.path)

  router = createRouter(
    history: createWebHistory()
    routes: routes
  )
  trackRouting(router)

  createApp(
    mixins: [ContextParameterHelper]

    data: ->
      elementData = JSON.parse(element.getAttribute('data'))

      agentTeams: []
      availableTags: []
      contextParameters: []
      bots: []
      activeBot: null
      currentBot: null
      userSettings: elementData.settings || {}

    computed:
      currentRoute: ->
        return {} unless @$route.name?
        @$router.options.routes.find (route) => route.name == @$route.name

    watch:
      '$route.name': -> @setBreadcrumbs()
      '$route.params.templateId': -> @setBreadcrumbs()

    created: ->
      @loadAgentTeams()
      @loadAvailableTags()
      @loadContextParameters()
      @loadBots()

    methods:
      loadAgentTeams: ->
        @agentTeams = await AgentTeam.list()
      loadAvailableTags: ->
        @availableTags = await AnswerTemplate.availableTags()
      loadContextParameters: ->
        @contextParameters = await BotbuilderApi.getContextParameters()
      loadBots: ->
        BotbuilderApi.getBots().then (bots) =>
          @setActiveBot(bots).then => @bots = bots
      setActiveBot: (bots) ->
        Bot.active(bots: bots, light: false)
          .then (activeBot) =>
            @currentBot = @activeBot = activeBot
      setBreadcrumbs: ->
        if @currentRoute.breadcrumbParent?
          parentRoute = @$router.options.routes.find (route) => route.name == @currentRoute.breadcrumbParent
          window.breadcrumbs.strip().append(
            text: parentRoute.label
            callback: => @$router.push(name: parentRoute.name)
          )
        else
          window.breadcrumbs.strip()
          window.breadcrumbs.append(text: @currentRoute.label) if @currentRoute.label?

    template: '
      <router-view/>
    '
  )
    .use(router)
    .mount(element)


export default ->
  $(window).on 'turbolinks:load', ->
    if element = document.getElementById('answer-templates-app')
      initAnswerTemplatesApp(element)
