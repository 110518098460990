
  import KnowledgeSourceBaseUrl from 'models/knowledge_source_base_url'
  import { confirm } from 'helpers'

  import { Button, Input, Label, Switch } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: KnowledgeSourceBaseUrl

    data: ->
      baseUrl: @modelValue

    methods:
      update: ->
        @$emit('update:modelValue', @baseUrl)
      addBlackPattern: ->
        @baseUrl.blackPatterns.push('')
      addWhitePattern: ->
        @baseUrl.whitePatterns.push('')
      removeBlackPattern: (index) ->
        @baseUrl.blackPatterns.splice(index, 1)
        @update()
      removeWhitePattern: (index) ->
        @baseUrl.whitePatterns.splice(index, 1)
        @update()

    components:
      Button: Button
      Input: Input
      Label: Label
      Switch: Switch
      XIcon: XIcon
