
  import KnowledgeSource from 'models/knowledge_source.coffee'

  import CrawlingEditor from './crawling_editor.vue'
  import DocumentAdder from './document_adder.vue'
  import { Input, Label, RadioPanels, Textarea } from 'components/generic'
  import { ChevronDownIcon, XIcon } from '@heroicons/vue/outline'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

  export default
    props:
      sourceLabel: String

    data: ->
      newSource = new KnowledgeSource(label: @sourceLabel)

      sourceType: newSource.type
      newSource: newSource
      files: []
      textFileName: ''
      textFileContent: ''
      sourceTypeOptions: [
        {key: 'http_crawler', label: 'Website', description: 'Extract knowledge from a website'}
        {key: 'documents', label: 'Documents', description: 'Upload documents'}
        {key: 'text', label: 'Text', description: 'Enter text'}
      ]

    computed:
      valid: ->
        @newSource.label.length &&
          switch @sourceType
            when 'http_crawler'
              @newSource.baseUrls[0]?.baseUrl.length
            when 'documents'
              true
            when 'text'
              @textFileName.length

    methods:
      setType: (type) ->
        @sourceType = type
        @newSource.type = if type == 'text' then 'documents' else type
      addFiles: (files) ->
        @files = @files.concat(files)
      removeFile: (index) ->
        @files.splice(index, 1)
      create: ->
        if @sourceType == 'text'
          @files = [new File([@textFileContent], @textFileName)]
        @newSource.save()
          .then =>
            @newSource.upload(@files)
            @newSource
      cancel: ->
        @newSource = new KnowledgeSource(label: @sourceLabel)

    components:
      ChevronDownIcon: ChevronDownIcon
      CrawlingEditor: CrawlingEditor
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      DocumentAdder: DocumentAdder
      Input: Input
      Label: Label
      RadioPanels: RadioPanels
      Textarea: Textarea
      XIcon: XIcon
