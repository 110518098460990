
  import AnswerTemplate from 'models/answer_template'

  import { Badge, Button, Checkbox, Combobox, Label } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: AnswerTemplate

    data: ->
      template: @modelValue
      tagToAdd: null
      tags: @availableTags
      teamIds: {}

    computed:
      availableTags: ->
        @$root.availableTags
          .filter (tag) => if @template? then !@template.tags.includes(tag) else true
          .sort()

    watch:
      '$root.availableTags': ->
        @tags = @availableTags

    created: ->
      @init()

    methods:
      init: ->
        @teamIds = Object.fromEntries(
          @$root.agentTeams.map (team) =>
            [team.id, @template.teams.map((t) -> t.id).includes(team.id)]
        )
        @tags = @availableTags
      filterTags: (tag, query) ->
        tag.toLowerCase().startsWith(query.toLowerCase())
      setTags: (query) ->
        @tags = [query].concat(@availableTags)
      removeTag: (index) ->
        @template.tags.splice(index, 1)
        @update()
      updateTags: ->
        return if !@tagToAdd
        @template.tags.push(@tagToAdd)
        @$refs.tagCombobox.resetQuery()
        @tagToAdd = null
        @tags = @availableTags
        @update()
      updateTeamIds: ->
        @template.teams = @$root.agentTeams.filter (team) => @teamIds[team.id]
        @update()
      update: ->
        @$emit('update:modelValue', @template)

    components:
      Badge: Badge
      Button: Button
      Checkbox: Checkbox
      Combobox: Combobox
      Label: Label
      XIcon: XIcon
