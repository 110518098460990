import AnswerTemplate from 'models/answer_template'

Vue.component 'inbox2-answer-template',
  props:
    template: AnswerTemplate
    conversationId: String

  methods:
    use: (e) ->
      @$root.track('Inbox answer template used', conversationId: @conversationId, templateId: @template.id)
      @$emit('use', @template)

  template: '
    <div @click.self="use" class="answer-template">
      <div @click="use" class="answer-template-content">
        <strong>{{ template.exampleQuestion }}</strong>
        <inbox2-bubble v-for="bubble in template.bubbles" :bubble-data="bubble"/>
        <ul class="tag-list">
          <li v-for="tag in template.tags">
            <span class="tag">{{ tag }}</span>
          </li>
        </ul>
      </div>
    </div>
  '
