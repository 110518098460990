
  import Availability from 'models/availability'
  import { weekdays } from 'rails_data'

  import { Button, Checkbox, DatetimePicker, TimezoneSelector } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'
  import { PlusIcon } from '@heroicons/vue/solid'

  export default
    props:
      modelValue: Availability

    data: ->
      availability: @modelValue
      weekdays: weekdays
      selectedWeekdays: []
      PlusIcon: PlusIcon

    created: ->
      @setSelectedWeekdays()

    methods:
      setSelectedWeekdays: ->
        @selectedWeekdays = @availability.serviceHours.map (period) =>
          Object.fromEntries(weekdays.map (day) -> [day, period.days.includes(day.toUpperCase())])
      addPeriod: ->
        @availability.addPeriod()
        @setSelectedWeekdays()
      removePeriod: (index) ->
        @availability.removePeriod(index)
        @setSelectedWeekdays()
        @update()
      updateDays: ->
        @availability.serviceHours.forEach (period, index) =>
          period.days = Object.entries(@selectedWeekdays[index])
            .map ([day, val]) -> day.toUpperCase() if val
            .compact()
        @update()
      update: ->
        @$emit('update:modelValue', @availability)

    components:
      Button: Button
      Checkbox: Checkbox
      DatetimePicker: DatetimePicker
      TimezoneSelector: TimezoneSelector
      XIcon: XIcon
