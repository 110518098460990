
  import Conversation from 'models/conversation'

  import ChannelLogo from 'components/channels/channel_logo.vue'
  import { UserIcon } from '@heroicons/vue/outline'

  export default
    emits: ['set']

    props:
      conversation: Conversation
      isCurrent: Boolean

    data: ->
      handoverWaitTime: null
      handoverWaitTimeTimeout: null

    computed:
      teaser: ->
        "#{@conversation.lastMessageSource?.toLowerCase().capitalize()}: #{@conversation.lastMessageText}"
      handoverWaitTimeFormatted: ->
        return if !@handoverWaitTime?
        l(@handoverWaitTime, 'duration')
      handoverWaitTimeClass: ->
        return 'text-MercuryRed-700' if @handoverWaitTime >= @conversation.handoverSession?.responseTimeThresholds?.red
        return 'text-MercuryYellow-700' if @handoverWaitTime >= @conversation.handoverSession?.responseTimeThresholds?.yellow
        'text-gray-500'
      statusIndicatorColorClass: ->
        switch @conversation.status
          when 'ONLINE'
            'bg-MercuryGreen-600'
          when 'IDLE'
            'bg-gray-400'
          else
            'invisible'
      handoverIndicatorColorClass: ->
        return 'invisible' if !@conversation.isInHandoverMode
        return 'text-MercuryGreen-600' if @conversation.handoverSession?.assignedAgentId == @$root.agent?.id
        return 'text-MercuryYellow-600' if @$root.agent?.teamIds.includes(@conversation.handoverSession?.assignedTeamId)
        'text-gray-400'

    watch:
      'conversation.handoverSession':
        handler: -> @setHandoverWaitTime()
        deep: true

    created: ->
      @setHandoverWaitTime()

    methods:
      setHandoverWaitTime: ->
        clearTimeout(@handoverWaitTimeTimeout)
        if !@conversation.isInHandoverMode
          @handoverWaitTime = null
          return
        if @conversation.handoverSession.timeLastResponse?
          @handoverWaitTime = moment().diff(moment(@conversation.handoverSession.timeLastResponse))
        else
          @handoverWaitTime = moment().diff(moment(@conversation.handoverSession.timeOpened))
        @handoverWaitTimeTimeout = setTimeout(@setHandoverWaitTime, 1000)
      select: ->
        @conversation.lastMessageReadByAnyAgent = true
        @$emit('set', @conversation)

    components:
      ChannelLogo: ChannelLogo
      UserIcon: UserIcon
