import Conversation from 'models/conversation'
import { alert } from 'helpers'
import VMdEditor from 'v_md_editor'

Vue.component 'inbox2-handover-interface',
  props:
    conversation: Conversation

  data: ->
    newMessage: ''
    usedAnswerTemplateId: null
    addMessageToAnswerTemplates: false

  computed:
    operatorMode: ->
      if @conversation? && @$root.session.permissions.stages?[@conversation.stageId]?.answer
        if @conversation.isInHandoverMode
          return 'write-message'
        else
          return 'prompt-handover'
      'inactive'
    showInput: ->
      @operatorMode in ['write-message']
    isMobileSafari: ->
      navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1 &&
        navigator.userAgent.match(/iP(ad|hone)/i)

  mounted: ->
    @$refs.editor.focus()
    $(@$refs.editor.$el).on 'keyup', (e) =>
      if e.which == 13 && e.ctrlKey
        @sendMessage()

  methods:
    sendMessage: ->
      return if @$root.sending
      return if @newMessage.length == 0
      @$root.track('Inbox message sent', conversationId: @conversation.id)
      if @addMessageToAnswerTemplates
        @$root.track('Inbox messages saved as answer template', conversationId: @conversation.id, message: @newMessage)
        @$emit('add-to-answer-templates', @newMessage)
        @addMessageToAnswerTemplates = false
      @$root.sending = true
      @$root.api.sendTextMessage(@conversation, @newMessage, @usedAnswerTemplateId)
        .then =>
          @newMessage = ''
          @usedAnswerTemplateId = null
        .catch =>
          await alert('Sorry, sending message failed!')
        .finally =>
          @$root.sending = false
          @$refs.editor.focus()
    useTemplate: (template) ->
      @newMessage = template.messageText
      @usedAnswerTemplateId = template.id
      @$refs.editor.focus()
    endHandoverMode: ->
      @$root.track('Inbox handover session ended', conversationId: @conversation.id)
      @$root.sending = true
      @$root.api.stopHandover(@conversation)
        .then =>
          Vue.set(@conversation.handoverSession, 'timeClosed', new Date().toISOString())
        .catch => await alert('Sorry, handover mode could not be terminated!')
        .finally => @$root.sending = false

  components:
    'markdown-editor': VMdEditor

  template: '
    <div>
      <div class="message-editor-handover form-group">
        <markdown-editor
          v-if="!isMobileSafari"
          ref="editor"
          v-model="newMessage"
          left-toolbar="bold italic link"
          right-toolbar=""
          placeholder="Type a message ..."
          :codemirror-config="{lineNumbers: false, scrollbarStyle: \'simple\'}"
        />
        <textarea
          v-if="isMobileSafari"
          ref="editor"
          v-model="newMessage"
          placeholder="Type a message ..."
        />
        <div class="message-options">
          <checkbox v-model="addMessageToAnswerTemplates">
            {{ t("inbox.conversations.addAnswerTemplates") }}
          </checkbox>
        </div>
      </div>

      <div class="handover-buttons flex-container spread-content form-group">
        <button :class="{disabled: $root.sending}" @click="endHandoverMode" class="btn btn-default">
          {{ t(\'inbox.conversations.reEngageChatbot\') }}
        </button>
        <button :class="{disabled: $root.sending}" @click="sendMessage" class="btn btn-success">
          {{ t(\'inbox.conversations.buttonSend\') }}
        </button>
      </div>
    </div>
  '
