
  import Conversation from 'models/conversation.coffee'
  import PlatformApi from 'models/platform_api'
  import { cobrowsingUrl, railsEnv } from 'rails_data'
  import { alert, confirm, download, hexToRgba } from 'helpers'
  import cobrowsingIcon from 'images/co_browsing.png'

  import AnswerTemplateList from './answer_template_list.vue'
  import ContextParameterList from './context_parameter_list.vue'
  import NotesEditor from './notes_editor.vue'
  import RavensburgerTicketCreator from './ravensburger_ticket_creator.vue'
  import { ActionMenu, Badge, H3, Tabs } from 'components/generic'

  export default
    props:
      conversation: Conversation

    data: ->
      tabs = [
        {id: 'context', name: 'Context'}
        {id: 'ticket', name: 'Create ticket'} if railsEnv == 'production' && Globals.projectId == 333
        {id: 'notes', name: 'Notes'}
      ].compact()
      tabs: tabs
      currentTab: tabs[0].id
      hexToRgba: hexToRgba
      cobrowsingIcon: cobrowsingIcon

    computed:
      actionMenuItems: ->
        [
          { label: t('inbox.conversations.takeOver'), method: @initHandover } if @$root.mayAnswerInbox && !@conversation.isInHandoverMode
          { label: t('inbox.conversations.exportUserData'), method: @export }
          { label: t('inbox.conversations.deleteUser'), method: @deleteUser }
        ].filter (item) -> item?
      showAnswerTemplates: ->
        @conversation?.isInHandoverMode && @$root.mayAnswerInbox

    methods:
      initHandover: ->
        @$root.api.initHandover(@conversation)
          .then =>
            @conversation.handoverSession ||= {}
            @conversation.handoverSession.timeOpened = new Date().toISOString()
            @$root.loadAnswerTemplates()
          .catch => await alert('Sorry, handover could not be initiated!')
          .finally => @$root.sending = false
      export: ->
        rows = []
        Conversation.USER_ATTRIBUTES.forEach (attr) =>
          rows.push([attr, @conversation[attr]])
        @$root.contextParameters.forEach (cp) =>
          rows.push([cp.label, @conversation.context[cp.key]])
        csvString = rows.map((row) -> row.join(',')).join("\n")
        download('data:text/csv;charset=utf-8,' + encodeURIComponent(csvString), "#{@conversation.fullName}.csv")
      deleteUser: ->
        return unless await confirm("Do you really want to delete user “#{@conversation.fullName}”?")
        PlatformApi.Inbox.deleteUser(@conversation)
          .then => @$emit('unset-conversation')
      openCobrowsing: ->
        window.open("#{cobrowsingUrl}?room=#{@conversation.coBrowsingSessionId}",'pagename','resizable=0,height=600,width=1100')
        return false

    components:
      ActionMenu: ActionMenu
      AnswerTemplateList: AnswerTemplateList
      Badge: Badge
      ContextParameterList: ContextParameterList
      H3: H3
      NotesEditor: NotesEditor
      RavensburgerTicketCreator: RavensburgerTicketCreator
      Tabs: Tabs
