
  import AgentTeam from 'models/agent_team'

  import AgentSelector from './agent_selector.vue'
  import AvailabilityEditor from './availability_editor.vue'
  import { BackLink, Badge, Button, H3, Input, Label, PageHeader, RadioPanels, Switch } from 'components/generic'
  import { ArrowUpIcon, PencilIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      team: null
      teamAgents: {}
      debounceTimeout: null
      editing: false
      AgentTeam: AgentTeam
      PencilIcon: PencilIcon

    watch:
      '$root.agentTeams': ->
        @setTeam()

    created: ->
      @setTeam()

    methods:
      setTeam: ->
        @team = @$root.agentTeams.find (team) => team.id == @$route.params.teamId
        window.breadcrumbs.append(text: @team.label) if @team?
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout((=> @team.save()), 1000)
      goToRouting: ->
        Turbolinks.visit(Routes.handover_tags_project_path(Globals.projectId, Globals.stageLevel))

    components:
      AgentSelector: AgentSelector
      ArrowUpIcon: ArrowUpIcon
      AvailabilityEditor: AvailabilityEditor
      BackLink: BackLink
      Badge: Badge
      Button: Button
      H3: H3
      Input: Input
      Label: Label
      PageHeader: PageHeader
      RadioPanels: RadioPanels
      Switch: Switch
