
  import AgentTeam from 'models/agent_team'
  import HandoverConfig from 'models/handover_config'
  import Project from 'models/project'
  import slackLogo from 'images/logo_slack.svg'
  import { confirm } from 'helpers'

  import { ActionMenu, Badge, Button, H4, Input, Label, PageHeader, Switch, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'
  import { ChevronDownIcon, PencilIcon, XIcon } from '@heroicons/vue/outline'
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'

  export default
    data: ->
      handoverConfig: null
      project: null
      debounceTimeoutConfig: null
      debounceTimeoutProject: null
      PlusIcon: PlusIcon
      slackLogo: slackLogo

    created: ->
      @loadSettings()

    methods:
      loadSettings: ->
        HandoverConfig.load().then (config) => @handoverConfig = config
        Project.load(Globals.projectId).then (project) => @project = project
      routerTarget: (team) ->
        {name: 'agentTeam', params: {teamId: team.id}}
      actionMenuItems: (team) ->
        [
          {icon: PencilIcon, label: 'Edit', method: => @$router.push(@routerTarget(team))}
          {icon: XIcon, label: 'Delete', method: => @remove(team)}
        ]
      remove: (team) ->
        return unless await confirm("Really delete agent team “#{team.label}”?")
        team.delete()
          .then =>
            @$root.agentTeams = @$root.agentTeams.filter (t) => t.id != team.id
      createNewTeam: ->
        @$router.push(name: 'newAgentTeam')
      updateConfig: ->
        clearTimeout(@debounceTimeoutConfig)
        @debounceTimeoutConfig = setTimeout(@handoverConfig.save, 1000)
      updateProject: ->
        clearTimeout(@debounceTimeoutProject)
        @debounceTimeoutProject = setTimeout(@project.save, 1000)

    components:
      ActionMenu: ActionMenu
      Badge: Badge
      Button: Button
      ChevronDownIcon: ChevronDownIcon
      Disclosure: Disclosure
      DisclosureButton: DisclosureButton
      DisclosurePanel: DisclosurePanel
      H4: H4
      Input: Input
      Label: Label
      PageHeader: PageHeader
      Switch: Switch
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
