
  import AnswerTemplate from 'models/answer_template'
  import Message from 'models/message'
  import { confirm } from 'helpers'

  import MessageEditor from 'components/botbuilder/nlg/message_editor.vue'
  import AnswerTemplateMetaEditor from './answer_template_meta_editor.vue'
  import { BackLink, Badge, Button, H3, Input, PageHeader } from 'components/generic'
  import { PencilIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      template: null
      availableMessageTemplates: Object.fromEntries(
        Message.TEMPLATES_PER_CHANNEL_TYPE.default
          .filter (t) -> t != 'delay'
          .map (t) -> [t, Message.TEMPLATES[t]]
      )
      messages: {}
      selectedTemplateType: 'text'
      editing: false
      showMessageEditor: true
      debounceTimeout: null
      PencilIcon: PencilIcon

    created: ->
      @loadTemplate()

    methods:
      loadTemplate: ->
        @template = await AnswerTemplate.load(@$route.params.templateId)
        @selectedTemplateType = @template.message.templateType
        @messages[@selectedTemplateType] = @template.message
      setMessageTemplate: (templateType) ->
        @showMessageEditor = false
        @$nextTick =>
          @selectedTemplateType = templateType
          if @messages[templateType]?
            @template.message = @messages[templateType]
          else
            @messages[templateType] = @template.setMessageTemplate(templateType)
          @showMessageEditor = true
          @update()
      update: ->
        clearTimeout(@debounceTimeout)
        @debounceTimeout = setTimeout(@template.save, 1000)

    components:
      AnswerTemplateMetaEditor: AnswerTemplateMetaEditor
      BackLink: BackLink
      Badge: Badge
      Button: Button
      H3: H3
      Input: Input
      MessageEditor: MessageEditor
      PageHeader: PageHeader
