
  import AnswerTemplate from 'models/answer_template'
  import PlatformApi from 'models/platform_api'

  import { ActionMenu, Badge, Button, MultiselectDropdown, PageHeader, Table, TableCell, TableHeadCell, TableHeadRow, TableRow, TableWrapper } from 'components/generic'
  import { PlusIcon } from '@heroicons/vue/solid'
  import { PencilIcon, XIcon } from '@heroicons/vue/outline'

  export default
    data: ->
      templates: []
      filteredTemplates: []
      PlusIcon: PlusIcon

    computed:
      filters: ->
        @$root.userSettings?.filters
      availableTags: ->
        @$root.availableTags.sort().map (tag) -> {key: tag}
      availableTeams: ->
        @$root.agentTeams.sortByString('label').map (team) -> {id: team.id, label: team.label}

    created: ->
      @initSettings()
      @loadTemplates()

    methods:
      initSettings: ->
        @$root.userSettings.filters ||= {}
        @$root.userSettings.filters.tags ||= []
        @$root.userSettings.filters.teams ||= []
      loadTemplates: ->
        @templates = (await AnswerTemplate.list()).sortByString('lowerCaseExampleQuestion')
        @setTemplates()
      setTemplates: ->
        @saveSettings()
        @filteredTemplates = @templates.filter (template) =>
          tagFilter = if @filters.tags.length
            template.tags.some (tag) => @filters.tags.map((tag) -> tag.key).includes(tag)
          else
            true
          teamFilter = if @filters.teams.length
            template.teams.some (team) => @filters.teams.map((team) -> team.id).includes(team.id)
          else
            true
          tagFilter && teamFilter
      saveSettings: ->
        PlatformApi.Webapp.saveProjectSettingsForAllEnvironments(
          Globals.projectId,
          answer_templates: @$root.userSettings
        )
      routerTarget: (template) ->
        {name: 'answerTemplate', params: {templateId: template.id}}
      actionMenuItems: (template) ->
        [
          {icon: PencilIcon, label: 'Edit', method: => @$router.push(@routerTarget(template))}
          {icon: XIcon, label: 'Delete', method: => @remove(template)}
        ]
      remove: (template) ->
        template.delete()
          .then =>
            @templates = @templates.filter (t) => t.id != template.id
      createNewTemplate: ->
        @$router.push(name: 'newAnswerTemplate')

    components:
      ActionMenu: ActionMenu
      Badge: Badge
      Button: Button
      MultiselectDropdown: MultiselectDropdown
      PageHeader: PageHeader
      Table: Table
      TableCell: TableCell
      TableHeadCell: TableHeadCell
      TableHeadRow: TableHeadRow
      TableRow: TableRow
      TableWrapper: TableWrapper
