
  import Attachment from 'models/attachment.coffee'
  import Verbalization from 'models/verbalization.coffee'

  import PayloadEditor from './payload_editor.vue'
  import TextEditor from './text_editor.vue'
  import UniversalInput from 'components/botbuilder/inputs/universal_input.vue'
  import { Button, KeyListbox, Switch } from 'components/generic'
  import { XIcon } from '@heroicons/vue/outline'

  export default
    props:
      modelValue: Attachment
      context:
        type: String
        default: ''
      deletable:
        type: Boolean
        default: true
      maxLabelLength:
        type: Number
        default: null
      hideLabel:
        type: Boolean
        default: false
      alwaysEditing:
        type: Boolean
        default: false

    emits: ['update:modelValue', 'remove']

    data: ->
      buttonTypes = []
      if @context == 'quickreplies'
        buttonTypes.push(key: 'QuickReplyButton', label: 'intent')
      else
        buttonTypes.push(key: 'PostbackButton', label: 'intent')
        buttonTypes.push(key: 'LinkButton', label: 'Link') if !['whatsappButton', 'whatsappList'].includes(@modelValue.message.templateType)
      if @modelValue.message.canHaveAutogeneratedQuickreplies
        buttonTypes.push(key: 'AutogeneratedQuickReplyButtons', label: 'autogenerate')
      if @modelValue.bubble.canHaveProvideValueButtons
        if @context == 'quickreplies' || @modelValue.bubble.canHavePersistentProvideValueButtons
          buttonTypes.unshift(key: 'ProvideValueButton', label: 'provide value')
        else if @modelValue.type == 'ProvideValueButton'
          buttonTypes.unshift(key: 'ProvideValueButton', label: 'provide value', disabled: true)

      button: @modelValue
      editing: @alwaysEditing
      buttonTypes: buttonTypes
      buttonType: null
      webviewSizes: Attachment.WEBVIEW_SIZES .map (size) -> {key: size, label: size}
      countSelection: [2..10].map (number) -> {key: number}
      container: null

    computed:
      showError: ->
        !@button.pristine && !@button.valid
      contextParameter: ->
        return unless @button.host instanceof Verbalization
        @$root.contextParameters.find (cp) => cp.key == @button.host.parameterKey
      maxLengthBadgeClasses: ->
        return '' if !@button.label?
        if @button.label.text.length > @maxLabelLength
          'bg-MercuryRed-50 text-MercuryRed-800 font-normal'
        else
          'bg-gray-100 text-gray-800 font-light'
      components: ->
        return if !['FillSlotButton', 'ProvideValueButton', 'AutogeneratedQuickReplyButtons'].includes(@button.type)
        return if !@contextParameter?.components
        @contextParameter.components.map (component) =>
          component.clone().withKey(@contextParameter.key + '#' + component.key)
      fillSlotButtonComponent: ->
        return unless @components?
        @components.find (cmp) => cmp.key == @button.parameterKey
      fillSlotButtonDataType: ->
        isBoolean = @button.host instanceof Verbalization && [
          'ConfirmExistingVerbalization',
          'ConfirmUnderstoodVerbalization',
        ].includes(@button.host.type)
        if isBoolean
          return {valueType: 'bool', collectionType: 'none'}
        valueType: (@fillSlotButtonComponent || @contextParameter || {}).valueType
        collectionType: (@fillSlotButtonComponent || @contextParameter || {}).collectionType
        entitySchemaKey: (@fillSlotButtonComponent || @contextParameter || {}).entitySchemaKey
        contextParameter: (@fillSlotButtonComponent || @contextParameter || {}).parameter

    watch:
      modelValue: ->
        @button = @modelValue
        @setButtonType()
        @initParameterKey()
      components: ->
        @initParameterKey()

    created: ->
      @setButtonType()
      @initParameterKey()

    mounted: ->
      if @button.pristine
        # use timeout in order to avoid click on "add quick reply" cancelling editing mode
        setTimeout =>
          @startEditing()
          @$nextTick => @$refs.labelEditor?.focus()
        , 0
      # end editing mode when clicked outside
      @container = @$el.closest('.screen-overlay') || document
      @container.addEventListener('click', @clickedOutside)

    unmounted: ->
      @container.removeEventListener('click', @clickedOutside)

    methods:
      setButtonType: ->
        @buttonType = @button.type
      initParameterKey: ->
        return unless @components
        if @button.type == 'ProvideValueButton'
          @button.updates.forEach (update) =>
            update.parameterKey = @components[0].key if !update.parameterKey
        else if !@button.parameterKey
          @button.parameterKey = @components[0].key
      clickedOutside: (e) ->
        return if e.composedPath().includes(@$el)
        @endEditing()
      startEditing: ->
        @editing = true
      endEditing: ->
        return if @alwaysEditing
        @editing = false
        @button.pristine = false
      update: ->
        @$emit('update:modelValue', @button)
      updateButtonType: ->
        @button.setButtonType(@buttonType)
        @update()
      addUpdate: ->
        @button.addUpdate()
        @initParameterKey()
      removeUpdate: (index) ->
        @button.updates.splice(index, 1)
        @update()
      provideValueButtonDataType: (index) ->
        isBoolean = @button.host instanceof Verbalization && [
          'ConfirmExistingVerbalization',
          'ConfirmUnderstoodVerbalization',
        ].includes(@button.host.type)
        if isBoolean
          return {valueType: 'bool', collectionType: 'none'}
        valueType: (@provideValueButtonComponent(index) || @contextParameter || {}).valueType
        collectionType: (@provideValueButtonComponent(index) || @contextParameter || {}).collectionType
        entitySchemaKey: (@provideValueButtonComponent(index) || @contextParameter || {}).entitySchemaKey
        contextParameter: (@provideValueButtonComponent(index) || @contextParameter || {}).parameter
      provideValueButtonComponent: (index) ->
        return unless @components?
        @components.find (cmp) => cmp.key == @button.updates[index].parameterKey

    components:
      Button: Button
      KeyListbox: KeyListbox
      PayloadEditor: PayloadEditor
      Switch: Switch
      TextEditor: TextEditor
      UniversalInput: UniversalInput
      XIcon: XIcon
