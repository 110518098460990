
  import AnswerTemplate from 'models/answer_template'

  import AnswerTemplateMetaEditor from './answer_template_meta_editor.vue'
  import { BackLink, Input, Label, PageHeader, Panel } from 'components/generic'

  export default
    data: ->
      template: new AnswerTemplate()
      teamIds: []
      sending: false

    methods:
      cancel: ->
        @$router.push(name: 'answerTemplateList')
      updateTeamIds: ->
        @template.teams = @$root.agentTeams.filter (team) => @teamIds.includes(team.id)
      save: ->
        @sending = true
        @template.save()
          .then =>
            @$router.push(name: 'answerTemplate', params: {templateId: @template.id})
          .finally =>
            @sending = false

    components:
      AnswerTemplateMetaEditor: AnswerTemplateMetaEditor
      BackLink: BackLink
      Input: Input
      Label: Label
      PageHeader: PageHeader
      Panel: Panel
