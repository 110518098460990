import GuidGenerator from 'models/guid_generator.coffee'
import Bubble from 'models/bubble.coffee'
import DialogNode from 'models/dialog_node.coffee'
import Verbalization from 'models/verbalization.coffee'

import buttonIcon from 'images/message_builder/icn_button.svg'
import delayIcon from 'images/message_builder/icn_delay.svg'
import galleryIcon from 'images/message_builder/icn_gallery.svg'
import imageIcon from 'images/message_builder/icn_image.svg'
import cardIcon from 'images/message_builder/icn_card.svg'
import listIcon from 'images/message_builder/icn_list.svg'
import textIcon from 'images/message_builder/icn_text.svg'
import videoIcon from 'images/message_builder/icn_video.svg'

export default class Message
  @TEMPLATES =
    text:
      icon: textIcon
      canHaveButtons: false
      canHaveQuickreplies: ['FACEBOOK', 'INSTAGRAM', 'WEB']
      maxQuickreplies: {FACEBOOK: 13, INSTAGRAM: 13}
      maxBodyLength: {FACEBOOK: 2000, INSTAGRAM: 2000, WHATSAPP: 4096}
      message:
        templateType: 'text'
        bubbles: [
          attachments: [type: 'Text']
        ]
    image:
      icon: imageIcon
      canHaveButtons: ['FACEBOOK']
      canHaveQuickreplies: ['WEB']
      maxButtons: {FACEBOOK: 3}
      message:
        templateType: 'image'
        bubbles: [
          attachments: [type: 'Image']
        ]
    video:
      icon: videoIcon
      canHaveButtons: ['FACEBOOK']
      canHaveQuickreplies: ['WEB']
      maxButtons: {FACEBOOK: 3}
      message:
        templateType: 'video'
        bubbles: [
          attachments: [type: 'Video']
        ]
    button:
      icon: buttonIcon
      canHaveButtons: true
      canHaveQuickreplies: false
      maxButtons: {FACEBOOK: 3, INSTAGRAM: 3}
      maxBodyLength: {FACEBOOK: 640, INSTAGRAM: 80}
      message:
        templateType: 'button'
        bubbles: [
          attachments: [
            type: 'Text'
          ,
            type: 'PostbackButton'
          ]
        ]
    gallery:
      icon: galleryIcon
      canHaveButtons: true
      canHaveQuickreplies: false
      maxButtons: {FACEBOOK: 3, INSTAGRAM: 3}
      maxTitleLength: {FACEBOOK: 80, INSTAGRAM: 80}
      maxSubtitleLength: {FACEBOOK: 80, INSTAGRAM: 80}
      maxSlides: {FACEBOOK: 10, INSTAGRAM: 10}
      message:
        templateType: 'gallery'
        bubbles: [
          attachments: [
            type: 'Image'
          ,
            type: 'Text'
            textRole: 'TITLE'
          ,
            type: 'Text'
            textRole: 'SUBTITLE'
          ]
        ]
    card:
      icon: cardIcon
      canHaveButtons: true
      canHaveQuickreplies: false
      message:
        templateType: 'card'
        bubbles: [
          attachments: [
            type: 'Image'
          ,
            type: 'Text'
            textRole: 'TITLE'
          ,
            type: 'Text'
            textRole: 'SUBTITLE'
          ]
        ]
    whatsappButton:
      label: 'Button'
      icon: buttonIcon
      canHaveButtons: true
      canHaveQuickreplies: false
      maxButtons: 3
      maxBodyLength: 1024
      maxTitleLength: 60
      maxFooterLength: 60
      message:
        templateType: 'whatsappButton'
        bubbles: [
          attachments: [
            type: 'Text'
            textRole: 'TITLE'
          ,
            type: 'Text'
          ,
            type: 'Text'
            textRole: 'FOOTER'
          ,
            type: 'PostbackButton'
          ]
        ]
    whatsappList:
      label: 'List'
      icon: listIcon
      canHaveQuickreplies: false
      maxBodyLength: 1024
      maxTitleLength: 60
      maxFooterLength: 60
      maxSectionTitleLength: 24
      maxRowTitleLength: 24
      maxRowDescriptionLength: 72
      maxCtaLabelLength: 20
      maxSections: 20
      maxRows: 10
      message:
        templateType: 'whatsappList'
        bubbles: [
          {
            attachments: [
              type: 'Text'
              textRole: 'TITLE'
            ,
              type: 'Text'
            ,
              type: 'Text'
              textRole: 'FOOTER'
            ,
              type: 'Text'
              textRole: 'CTA'
            ]
          }
          # the following bubble corresponds to an action section
          {
            attachments: [
              type: 'Text'
              textRole: 'TITLE'
            ,
            # the following three attachments constitute a row
              type: 'Text'
              textRole: 'TITLE'
            ,
              type: 'Text'
              textRole: 'SUBTITLE'
            ,
              type: 'PostbackButton'
            ]
          }
        ]
    delay:
      icon: delayIcon

  @TEMPLATES_PER_CHANNEL_TYPE =
    FACEBOOK: ['text', 'image', 'video', 'button', 'gallery', 'delay']
    INSTAGRAM: ['text', 'image', 'video', 'button', 'gallery', 'delay']
    PHONE: ['text', 'delay']
    WHATSAPP: ['text', 'image', 'video', 'whatsappButton', 'whatsappList', 'delay']
    default: ['text', 'image', 'video', 'button', 'gallery', 'card', 'delay']

  @BUTTON_LABEL_MAX_LENGTH =
    FACEBOOK: 20
    INSTAGRAM: 20
    WHATSAPP: 20

  @template: (messageVariation, templateType) ->
    new Message(messageVariation, @TEMPLATES[templateType].message)

  constructor: (@messageVariation, data = {}) ->
    @id = GuidGenerator.newGuid()
    @templateType = data.templateType
    @delay = data.delay || 0
    @bubbles = (data.bubbles || []).map (bubbleData) => new Bubble(@, bubbleData)

  clone: ->
    new Message(@messageVariation, @export)

  addQuickreply: ->
    return unless @canHaveQuickreplies
    @bubbles[0].addQuickReplyButton()

  removeQuickreply: (id) ->
    return unless @canHaveQuickreplies
    @bubbles[0].removeAttachment(id)

  addSlide: (index) ->
    newBubble = new Bubble(@,
      attachments: [
          type: 'Image'
        ,
          type: 'Text'
          textRole: 'TITLE'
        ,
          type: 'Text'
          textRole: 'SUBTITLE'
        ]
      )
    @bubbles.splice(index + 1, 0, newBubble)

  addWhatsappListSection: (index) ->
    return if @templateType != 'whatsappList'
    newBubble = new Bubble(@,
      attachments: [
        type: 'Text'
        textRole: 'TITLE'
      ]
    )
    newBubble.addWhatsappListRow(0)
    @bubbles.splice(index + 1, 0, newBubble) # first bubble doesn't count

  removeWhatsappListSection: (index) ->
    return if @templateType != 'whatsappList'
    return if @bubbles.length <= 2
    return if index > @bubbles.length - 2
    @bubbles.splice(index + 1, 1) # first bubble doesn't count

  valueFor: (key) ->
    value = @templateConfig[key]
    if value instanceof Object
      value[@medium]
    else
      value

  Object.defineProperties @prototype,
    nlg:
      get: ->
        @messageVariation?.languageNlg?.nlg
    host:
      get: ->
        @nlg?.host
    medium:
      get: ->
        @messageVariation.medium.toUpperCase()
    canHaveQuickreplies:
      get: ->
        canHaveQuickreplies = @templateConfig.canHaveQuickreplies
        return false if !canHaveQuickreplies
        return true if canHaveQuickreplies == true
        canHaveQuickreplies.includes(@medium)
    canHaveAutogeneratedQuickreplies:
      get: ->
        return false if !(@canHaveQuickreplies || @templateType == 'whatsappButton')
        if @host instanceof DialogNode
          ['ActiveLearning', 'Knowledge'].includes(@host.moduleKey)
        else if @host instanceof Verbalization
          ['QueryVerbalization', 'RetryVerbalization'].includes(@host.type) &&
            @host.parameterKey.startsWith('contentSearch.')
        else
          false
    countOfAutogeneratedQuickrepliesCustomizable:
      get: ->
        return if !@canHaveAutogeneratedQuickreplies
        return false if @host instanceof DialogNode && ['ActiveLearning', 'Knowledge'].includes(@host.moduleKey)
        return false if @templateType == 'whatsappButton'
        true
    isLast:
      get: ->
        index = @messageVariation.messages.findIndex (message) => message.id == @id
        index == @messageVariation.messages.length - 1
    buttons:
      get: ->
        @bubbles.map((bubble) -> bubble.buttons).flat()
    quickreplyButtons:
      get: ->
        return [] if !@canHaveQuickreplies
        @buttons
    allAttachments:
      get: ->
        @bubbles
          .map (bubble) -> bubble.attachments
          .flat()
    text:
      get: ->
        @allAttachments
          .filter (attachment) -> attachment.type == 'Text'
          .map (attachment) -> attachment.text
          .join(' ')
    templateConfig:
      get: ->
        Message.TEMPLATES[@templateType] || {}
    buttonLabelMaxLength:
      get: ->
        Message.BUTTON_LABEL_MAX_LENGTH[@medium]
    valid:
      get: ->
        return false if @bubbles.length == 0
        switch @templateType
          when 'card'
            @bubbles[0].attachments[0].valid && # first image
              @bubbles.every (bubble) ->
                bubble.attachments[1].valid && # title
                bubble.attachments[3..].every (button) -> button.isButton && button.valid # buttons
          when 'gallery'
            @bubbles.every (bubble) ->
              bubble.attachments[0].valid && bubble.attachments[1].valid && # image and title
                bubble.attachments[3..].every (button) -> button.isButton && button.valid # buttons
          when 'whatsappButton'
            @bubbles.length == 1 && @bubbles[0].attachments.length >= 3 &&
              ['Text', 'Image', 'Video'].includes(@bubbles[0].attachments[0].type) &&
              @bubbles[0].attachments[1].type == 'Text' && @bubbles[0].attachments[1].valid &&
              @bubbles[0].attachments[3..].length <= 3 &&
              @bubbles[0].attachments[3..].every (button) -> button.type == 'PostbackButton' && button.valid
          when 'whatsappList'
            @bubbles.length >= 2 &&
              @bubbles[0].attachments.length == 4 &&
              @bubbles[0].attachments[1].type == 'Text' && @bubbles[0].attachments[1].valid && # body
              @bubbles[0].attachments[3].type == 'Text' && @bubbles[0].attachments[3].valid && # CTA
              @bubbles[1..-1].every (bubble) =>
                (bubble.attachments.length - 1) %% 3 == 0 && bubble.whatsappListRows.every (row) =>
                  row[0].type == 'Text' && row[0].valid &&
                  row[2].type == 'PostbackButton' && row[2].payload.valid
          else
            @bubbles.every (bubble) -> bubble.valid
    export:
      get: ->
        templateType: @templateType
        delay: @delay
        bubbles: @bubbles.map (bubble) -> bubble.export
    dialogModule:
      get: -> @host?.dialogModule
    dialogNode:
      get: -> if @host instanceof DialogNode then @host else null
